import React from 'react';
import App from './App';
import Header from '../Header/Header';
import _config from '../../config';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { requestGroups, setActiveGroup,setApiUrl, setResponsiveVoice, setIconAndName, setCurrentComponent} from '../../actions';

class Selector extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.setGroup = this.setGroup.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.checkGroupAndUrl = this.checkGroupAndUrl.bind(this);
    }

    fetchData(url) {
        fetch(url + 'getdata/')
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((application) => {
                this.props.requestGroups(application);
            })
            .catch(() => {
                this.props.requestGroups({
                    application: {
                        marqueeStrings: ['Let op: geen verbinding']
                    }
                });

            });
    }

    fetchApiUrl() {
        fetch('https://files.smartdevelopment.nl/'+this.props.client+'/getApiUrl.php')
            .then((response) => {
                if(response.ok) {
                    return response.json();
                }
            })
            .then((response) => {
                localStorage.setItem('apiURL', response);

                this.checkGroupAndUrl();
            })
            .catch(() => {
                this.props.requestGroups({
                    application: {
                        marqueeStrings: ['Let op: geen verbinding']
                    }
                });
            });
    }

    renderOptions (key) {
        return  <div key={key} className="selectable-group" onClick={() => this.setGroup(key)}>{this.props.groupData.groups[key]}</div>
    }

    renderEmpty() {
        return  <div className="selectable-group">Geen groepen</div>
    }

    setGroup(key) {
        this.props.setActiveGroup({activeGroup: key});
        localStorage.setItem('activeGroup', key);
        localStorage.setItem('activeGroupName', this.props.groupData.groups[key]);
    }

    checkGroupAndUrl() {
        const key = localStorage.getItem('activeGroup');
        const apiURL = localStorage.getItem('apiURL');
        if(apiURL) {
            this.props.setApiUrl({apiURL: apiURL});
            if(key) {
                this.props.setActiveGroup({activeGroup: key});
            }else{
                this.fetchData(apiURL);
            }
        }else{
            this.fetchApiUrl();
        }
    }

    componentDidMount() {
        this.checkGroupAndUrl();
        this.props.setCurrentComponent({currentComponent:'selector'});
        this.props.setIconAndName({routeName:'selector', routeIcon:''});
        this.props.setResponsiveVoice(window.responsiveVoice);
    }

    render() {
        return (
            <div>
                {this.props.groupData.activeGroup ? '' :  <Header />}
                {this.props.groupData.groups ? this.props.groupData.activeGroup ? <App apiURL={this.props.apiUrl} groupId={this.props.groupData.activeGroup} /> : Object.keys(this.props.groupData.groups).map(this.renderOptions) : this.renderEmpty()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        groupData: state.groups,
        apiUrl: state.apiUrl.ApiUrl,
        responsiveVoice: state.appData.responsiveVoice,
        client: state.appData.client,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestGroups: data => dispatch(requestGroups(data)),
        setActiveGroup: data => dispatch(setActiveGroup(data)),
        setApiUrl: data => dispatch(setApiUrl(data)),
        setIconAndName: data => dispatch(setIconAndName(data)),
        setCurrentComponent: data => dispatch(setCurrentComponent(data)),
        setResponsiveVoice: responsiveVoice => dispatch(setResponsiveVoice(responsiveVoice))
    };
};

const RootSelector = withRouter(connect(mapStateToProps, mapDispatchToProps)(Selector));

export default RootSelector
