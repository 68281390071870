import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import {withRouter} from 'react-router';
class DayList extends React.Component{
	render() {

		return(

			<div id="days" className={this.props.color}>

		        <ul id="list-days">
		        	<ReactCSSTransitionGroup transitionName="days" transitionAppear={true} transitionEnter={true} transitionLeave={true} transitionAppearTimeout={150000000} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
			             	<li onClick={() => this.props.responsiveVoice.speak('Maandag', "Dutch Female", {volume: this.props.appData.volume})} className="daynum-1">
			             		<NavLink to={this.props.basePath + '/1'} className="dotw" activeClassName="active">maandag</NavLink>
				                <div className="day-img daynumber-1"></div>
				            </li>
				            <li onClick={() => this.props.responsiveVoice.speak('Dinsdag', "Dutch Female", {volume: this.props.appData.volume})} className="daynum-2">
				                <NavLink to={this.props.basePath + '/2'} className="dotw" activeClassName="active">dinsdag</NavLink>
				                <div className="day-img daynumber-2"></div>
				            </li>
				            <li onClick={() => this.props.responsiveVoice.speak('Woensdag', "Dutch Female", {volume: this.props.appData.volume})} className="daynum-3">
				                <NavLink to={this.props.basePath + '/3'} className="dotw" activeClassName="active">woensdag</NavLink>
				                <div className="day-img daynumber-3"></div>
				            </li>
				            <li onClick={() => this.props.responsiveVoice.speak('Donderdag', "Dutch Female", {volume: this.props.appData.volume})} className="daynum-4">
				                <NavLink to={this.props.basePath + '/4'} className="dotw" activeClassName="active">donderdag</NavLink>
				                <div className="day-img daynumber-4"></div>
				            </li>
				            <li onClick={() => this.props.responsiveVoice.speak('Vrijdag', "Dutch Female", {volume: this.props.appData.volume})} className="daynum-5 ">
				                <NavLink to={this.props.basePath + '/5'} className="dotw" activeClassName="active">vrijdag</NavLink>
				                <div className="day-img daynumber-5"></div>
				            </li>
				            <li onClick={() => this.props.responsiveVoice.speak('Zaterdag', "Dutch Female", {volume: this.props.appData.volume})} className="daynum-6">
				                <NavLink to={this.props.basePath + '/6'} className="dotw" activeClassName="active">zaterdag</NavLink>
				                <div className="day-img daynumber-6"></div>
				            </li>
				            <li onClick={() => this.props.responsiveVoice.speak('Zondag', "Dutch Female", {volume: this.props.appData.volume})} className="daynum-7">
				                <NavLink to={this.props.basePath + '/7'} className="dotw" activeClassName="active">zondag</NavLink>
				                <div className="day-img daynumber-7"></div>
				            </li>
			        </ReactCSSTransitionGroup>
		        </ul>
		    </div>
		)
	}
}
function mapStateToProps(state) {
    return {
    	appData: state.appData,
        responsiveVoice: state.appData.responsiveVoice
    };
}
export default withRouter(connect(mapStateToProps)(DayList));
