import { REQUEST_GROUPS, SET_GROUP } from '../actions';

const initialState = {
   groups: [],
   activeGroup: ''
}

export default function AppDataReducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_GROUPS:
            return {
                ...state,
                groups: action.payload
            };

        case SET_GROUP:
            return {
                ...state,
                activeGroup: action.payload.activeGroup,
            };

        default:
            return state;
    }
}
