import React from 'react';
import ClientBlock from '../Present/ClientBlock';
import { connect } from 'react-redux';
import {setIconAndName, setCurrentComponent} from '../../actions';

class Present extends React.Component{
	componentDidMount() {
		this.props.setCurrentComponent({currentComponent:'present'});
		this.props.setIconAndName({routeName:'Home', routeIcon:'//files.smartdevelopment.nl/'+this.props.client+'/wonen/icons/present.png'});
	}

	renderBlocks(blocks, from, to) {
		let aItems = [];
		let i = 1;
		for (let prop in blocks) {
			if(i <= to && i >= from) {
			    if (blocks.hasOwnProperty(prop)) {
			        let item = blocks[prop];
			        aItems.push(this.renderItem(item));
			    }
			}
			i += 1;
		}
		return aItems;

	}

	renderClients(clientsList) {
        const elementsPerRow = 3;

        var aItems = [];

        for (var i = 1; i <= clientsList.length; i += elementsPerRow) {
            aItems.push(
                <div className="row">
                    {this.renderBlocks(clientsList, i, i + elementsPerRow - 1)}
                </div>
            );
        }

        return aItems;
	}


	renderItem(item) {
        return <ClientBlock item={item} key={item.id}/>
	}

	renderContent() {
  		var dayNumber = new Date().getDay();
  		dayNumber = dayNumber === 0 ? 7 : dayNumber; // change sunday from 0 to 7

  		var presentClients = [];
  		var nonPresentClients = [];

  		if (this.props.appData.application.clients){
  			for (var id in this.props.appData.application.clients[dayNumber]) {
	            if (this.props.appData.application.clients[dayNumber][id].is_present === "1") {
	                presentClients.push(this.props.appData.application.clients[dayNumber][id]);
	            }
	            else {
	                nonPresentClients.push(this.props.appData.application.clients[dayNumber][id]);
	            }
        	}
  		}

  		presentClients.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
  		nonPresentClients.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

		if(this.props.appData.application.clients){
			return(
				<div id="PresentBody">
					<div className="PresentTop">
						<div className="PresentTopLeft">
							<div className="PresentTopLeftContent">
								AANWEZIG
							</div>
						</div>
						<div className="PresentTopRight">
							<div className="PresentTopRightContent">
								AFWEZIG
							</div>
						</div>
					</div>
					<div className="PresentBottom">
						<div className="PresentBottomLeft">
							{this.renderClients(presentClients)}
						</div>
						<div className="PresentBottomRight">
							{this.renderClients(nonPresentClients)}
						</div>
					</div>

		        </div>
			)
		}
	}

	render() {

		return (

			<div>
		    	{this.renderContent()}
			</div>
		)
	}
}


function mapStateToProps(state) {
    return {
        appData: state.appData,
        client: state.appData.client,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIconAndName: data => dispatch(setIconAndName(data)),
        setCurrentComponent: data => dispatch(setCurrentComponent(data)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Present);
