import React from 'react';
import { connect } from 'react-redux';

class HomeBlock extends React.Component{


	render() {

		const data = this.props.data;

		if(data.type !== 'menu') {
			return (

		        <div className={'home col-4 last ' + data.type} id="day" onClick={()=> this.props.responsiveVoice.speak(data.content.replace(/<(?:.|\n)*?>/gm, ''), "Dutch Female", {volume: this.props.appData.volume})}> 
		            <span className="block-title">{data.title}</span>

		            <span className={"block-content " + data.contentSize} dangerouslySetInnerHTML={{ __html: data.content }}></span>


		            {data.icon ? <img src={data.icon} id="shift-icon-image" alt={data.title}/> : ''}

		            {data.image ? <img src={data.image} id="shift-detail-image" alt={data.image}/> : ''}
		        </div>
		        )
	    }else{

	    	return (

		        <div className={'home col-4 last ' + data.type} id="day" onClick={()=> this.props.responsiveVoice.speak(data.title, "Dutch Female", {volume: this.props.appData.volume})}>
		            <span className="block-title">{data.title}</span>

		            <span className={"block-content " + data.contentSize}>
		            	{data.icon ? <img src={data.icon} id="menu-icon-image" alt={data.title}/> : ''}
		            </span>
		        </div>
		        )
	    }
	}
}


function mapStateToProps(state) {
    return {
        appData: state.appData,
		responsiveVoice: state.appData.responsiveVoice
    };
}



export default connect(mapStateToProps)(HomeBlock);
