import React from 'react';
import Client from '../Activities/Client';
import {connect} from 'react-redux';
class ActivityPopup extends React.Component{

	constructor(props, context) {
		super(props, context);

		this.renderClients = this.renderClients.bind(this);
	}
	renderClients(key) {

		return (<Client client={this.props.clients[key]} key={this.props.clients[key].id + "_" + this.props.activity} activityId={this.props.activity} day={this.props.day}/>)

	}

	shouldDisplay() {
		if(this.props.display) {
			return {display : 'block'}
		}else{
			return {display : 'none'}
		}
	}

	render() {

		return (
			<div className="popupholder" style={this.shouldDisplay()}>
				<div id="overlay" className="visible" >

				</div>
				<div id="popup" className="visible" onClick={this.props.removePopup}>
				        <div className="popupcontent">
				            <div className="left-content" >
				                <div className="inner">
				                    <h2 onClick={()=> this.props.responsiveVoice.speak(this.props.name, "Dutch Female", {volume: this.props.appData.volume})} className="activityname">{this.props.name}</h2>
				                      <div onClick={()=> this.props.responsiveVoice.speak(this.props.content.replace(/<(?:.|\n)*?>/gm, ''), "Dutch Female", {volume: this.props.appData.volume})} dangerouslySetInnerHTML={{ __html: this.props.content }} />
				                </div>
				            </div>
				            <div className="right-content">
				                <div className="inner">
				                    <h2 className="activityname" onClick={()=> this.props.responsiveVoice.speak('Wie gaan er mee?', "Dutch Female", {volume: this.props.appData.volume})}>Wie gaan er mee?</h2>
				                    <ul className="clientlist">
				                       {this.props.clients ? Object.keys(this.props.clients).map(this.renderClients) : ''}
				                    </ul>
				                </div>
				            </div>
				        </div>
				    </div>
				</div>

		)
	}
}

function mapStateToProps(state) {
    return {
    	appData: state.appData,
		responsiveVoice: state.appData.responsiveVoice
    };
}


export default connect(mapStateToProps)(ActivityPopup);
