import React from 'react';
import ForecastWeekSchedule from '../Forecast/ForecastWeekSchedule';
import { connect } from 'react-redux';
import {setIconAndName, setCurrentComponent} from '../../actions';

class Forecast extends React.Component{
	componentDidMount() {
		this.props.setCurrentComponent({currentComponent:'forecast'});
		this.props.setIconAndName({routeName:'Home', routeIcon:'//files.smartdevelopment.nl/'+this.props.client+'/wonen/icons/present.png'});
	}

	renderContent() {
		if(this.props.appData.application.forecast) {
			return  <ForecastWeekSchedule forecast={this.props.appData.application.forecast} />
		}
	}

	render() {
		return (

			<div>
			    <div id="forecast">
			    	{this.renderContent()}

			     </div>
			</div>
		)
	}
}


function mapStateToProps(state) {
    return {
        appData: state.appData,
        client: state.appData.client,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIconAndName: data => dispatch(setIconAndName(data)),
        setCurrentComponent: data => dispatch(setCurrentComponent(data)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Forecast);
