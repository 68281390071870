import React from 'react';
import Client from '../Menu/Client';
import {connect} from 'react-redux';
class MenuPopup extends React.Component{

	constructor(props, context) {
		super(props, context);

		this.renderClients = this.renderClients.bind(this);
	}
	renderClients(key) {
		return (<Client client={this.props.clients[key]} key={this.props.clients[key].id + "_" + this.props.menu.id} menu_id={this.props.menu.id} day={this.props.day}/>)
	}

	shouldDisplay() {
		if(this.props.display) {
			return {display : 'block'}
		}else{
			return {display : 'none'}
		}
	}

	render() {

		return (
			<div className="popupholder" style={this.shouldDisplay()}>
				<div id="overlay" className="visible" >

				</div>
				<div id="popup" className="visible" onClick={this.props.removePopup}>
				        <div className="popupcontent">
				            <div className="left-content">
				                <div className="inner">
				                    <h2  onClick={() => this.props.speak(this.props.menu.afternoon_title, "Dutch Female", {volume: this.props.appData.volume})} className="activityname">{this.props.menu.afternoon_title}</h2>
				                     <div onClick={() => this.props.speak(this.props.menu.popupContent.replace(/<(?:.|\n)*?>/gm, ''), "Dutch Female", {volume: this.props.appData.volume})} dangerouslySetInnerHTML={{ __html: this.props.menu.popupContent }} />
				                </div>
				            </div>
				            <div className="right-content">
				                <div className="inner">
				                    <h2 onClick={() => this.props.speak('Wie eten er mee?', "Dutch Female", {volume: this.props.appData.volume})} className="activityname">Wie eten er mee?</h2>
				                    <ul className="clientlist">
				                       {this.props.clients ? Object.keys(this.props.clients).map(this.renderClients) : ''}
				                    </ul>
				                </div>
				            </div>
				        </div>
				    </div>
				</div>

		)
	}
}

function mapStateToProps(state) {
    return {
    	appData: state.appData,
		speak: state.appData.responsiveVoice.speak
    };
}


export default connect(mapStateToProps)(MenuPopup);
