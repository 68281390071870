import { connect } from 'react-redux';
import React from 'react';
import {setClientPresence} from '../../actions';

class ClientBlock extends React.Component{

	constructor(context, props) {
		super(context, props);

		this.setClientPresence = this.setClientPresence.bind(this);
	}

	setClientPresence() {
		let isPresentNew = 0
		if (parseInt(this.props.item.is_present, 10) === 0) {
			isPresentNew = 1;
		}

		var dayNumber = new Date().getDay();
  		dayNumber = dayNumber === 0 ? 7 : dayNumber; // change sunday from 0 to 7

		this.props.setClientPresence({clientId: this.props.item.id, isPresent: isPresentNew, dayId: dayNumber})

		var formData = new FormData();

        formData.append('clientId', this.props.item.id);
        formData.append('isPresent', isPresentNew);

        fetch(this.props.apiUrl + 'setclientpresence', {
            method: 'POST',
            headers: ({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin,Headers'
            }),
            body: formData
        });
	}

	render() {

		return (
            <div className={"col-4-holder"} onClick={()=> this.props.responsiveVoice.speak(this.props.item.name, "Dutch Female", {volume: this.props.appData.volume})}>
                <div className="block-left" style={{backgroundImage: 'url(' + this.props.item.image + ')'}}>

                </div>
                <div className="block-right" onClick={this.setClientPresence}>
                	<div className="block-right-content">
                		<span>{this.props.item.name}</span>
                	</div>
                </div>
            </div>
		)
	}
}


function mapStateToProps(state) {
    return {
    	appData: state.appData,
    	apiUrl: state.apiUrl.ApiUrl,
		responsiveVoice: state.appData.responsiveVoice
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setClientPresence: data => dispatch(setClientPresence(data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientBlock);
