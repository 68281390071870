//-- Action type
export const REQUEST_APPDATA = 'REQUEST_APPDATA';
export const SET_APPLICATION_TIME = 'SET_APPLICATION_TIME';
export const SET_ICON_AND_ROUTE = 'SET_ICON_AND_ROUTE';
export const SET_TIMESTAMP = 'SET_TIMESTAMP';
export const SET_CURRENT_COMPONENT = 'SET_CURRENT_COMPONENT';
export const SET_CLIENT_TO_MENU = 'SET_CLIENT_TO_MENU';
export const SET_CLIENT_PRESENCE = 'SET_CLIENT_PRESENCE';
export const SET_CLIENT_TO_ACTIVITY = 'SET_CLIENT_TO_ACTIVITY';
export const REQUEST_GROUPS = 'REQUEST_GROUPS';
export const SET_GROUP = 'SET_GROUP';
export const SET_API_URL = 'SET_API_URL';
export const DATA_FAILURE = 'DATA_FAILURE';
//-- Action creator
export function requestAppData(data = null) {
  //-- Action
  return {
    type: REQUEST_APPDATA,
    payload: data
  }
}

export function setClient(str = false) {
    return {
        type: 'SET_CLIENT',
        payload: str
    }
}

export function setAppTime(data = null) {
    return {
        type: SET_APPLICATION_TIME,
        payload: data
    }
}

export function setIconAndName(data = null) {
    return {
        type: SET_ICON_AND_ROUTE,
        payload: data
    }
}

export function setTimeStamp(data = null) {
    return {
        type: SET_TIMESTAMP,
        payload: data
    }
}

export function setCurrentComponent(data = null) {
  return {
        type: SET_CURRENT_COMPONENT,
        payload: data
    }
}

export function setClientToMenu(data = null) {
    return {
        type: SET_CLIENT_TO_MENU,
        payload: data
    }
}

export function setClientPresence(data = null) {
    return {
        type: SET_CLIENT_PRESENCE,
        payload: data
    }
}

export function setClientToActivity(data = null) {
    return {
        type: SET_CLIENT_TO_ACTIVITY,
        payload: data
    }
}

export function requestGroups(data = null) {
    return {
        type: REQUEST_GROUPS,
        payload: data
    }
}

export function setActiveGroup(data = null) {
    return {
        type: SET_GROUP,
        payload: data
    }
}

export function setApiUrl(data = null) {
    return {
        type: SET_API_URL,
        payload: data
    }
}

export function appDataFailure(data = null) {
    return {
        type: DATA_FAILURE,
        payload: data
    }
}

export function setResponsiveVoice(responsiveVoice = null) {
    return {
        type:'RESPONSIVE_VOICE',
        payload: responsiveVoice 
    }
}