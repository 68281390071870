import { SET_API_URL } from '../actions';

const initialState = {
   ApiUrl: ''
}

export default function ApiUrlReducer(state = initialState, action) {
    switch (action.type) {
        case SET_API_URL:
     
            return {
                ...state,
                ApiUrl: action.payload.apiURL
            };
        default:
            return state;
    }
}
