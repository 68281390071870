import React from 'react';

class Spinner extends React.Component{
  render() {
      return (
        <div className="spinner">
        </div>
      );
  }
}

export default Spinner;