import React from 'react';
import MarqueeText from '../Header/MarqueeText';
import { connect } from 'react-redux';

class Marquee extends React.Component{
  constructor(props, context) {
      super(props, context);
      this.state = {
        elements : {},
        currentlyRendered : '',
        currentRight : 100
      }

      this.changeText = this.changeText.bind(this);
      this.renderMarqueeStrings = this.renderMarqueeStrings.bind(this);
  }

  changeText(current) {

    if(this.props.appData.application.marqueeStrings) {
      if(current + 1 <= this.props.appData.application.marqueeStrings.length - 1) {
        current += 1;
      }else{
        current = 0;
      }
      let currentlyRendered = this.state.currentlyRendered;
      const now = Math.floor(Date.now() / 1000);

      currentlyRendered = <MarqueeText key={now} text={this.props.appData.application.marqueeStrings[current]} />;

      this.setState({currentlyRendered : currentlyRendered});

      setTimeout(this.changeText, 15000, current);
    }else{
      setTimeout(this.changeText, 1000);
    } 
  }

  renderMarqueeStrings(key) {
    return <span onClick={()=>this.props.responsiveVoice.speak(this.props.appData.application.marqueeStrings[key], "Dutch Female", {volume: this.props.appData.volume})} key={key} style={{marginRight: '150px'}}>{this.props.appData.application.marqueeStrings[key]}</span>
  }

  componentDidMount() {
    this.changeText(0);
  }
  render() {
      return (
        <div className="marquee">
          <div className="inner-holder">
            <marquee>{this.props.appData.application.marqueeStrings ? Object.keys(this.props.appData.application.marqueeStrings).map(this.renderMarqueeStrings) : ''}</marquee>
          </div>
        </div>
      );
  }
}
function mapStateToProps(state) {
    return {
        appData: state.appData,
        responsiveVoice: state.appData.responsiveVoice     
    };
}
export default connect(mapStateToProps)(Marquee);