
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux';
//-- Footer component
class Footer extends React.Component{

	render(show) {
		const d = new Date();
		const dayNumber = d.getDay();
		let dayNum = 1;
		if(dayNumber === 0) {
			dayNum = 7;
		}
		else{
			dayNum = dayNumber;
		}

		return(
			<footer>
				<nav id="footer-menu">
					<ul onClick={this.props.minifySpotify}>
						<ReactCSSTransitionGroup transitionName="menu" transitionAppear={true} transitionEnter={false} transitionLeave={false} transitionAppearTimeout={1500} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
							<NavLink onClick={()=> this.props.responsiveVoice.speak('Start', "Dutch Female", {volume: this.props.appData.volume})} exact to="/" activeClassName="active" className={this.props.currentComponent === 'home' ? 'active' : ''}>
								<li className="button" id="home" data-url="/site/index">
									<img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/home.png"} alt={''}/>
									<span className="title">Home</span>
								</li>
							</NavLink>
							<NavLink onClick={()=> this.props.responsiveVoice.speak('Eten', "Dutch Female", {volume: this.props.appData.volume})} to={"/menu/" + dayNum} activeClassName="active" className={this.props.currentComponent === 'menu' ? 'active' : ''}>
								<li className="button" id="food" data-url="/site/food">
									<img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/food.png"} alt={''}/>
									<span className="title">Eten</span>
								</li>
							</NavLink>
							<NavLink onClick={()=> this.props.responsiveVoice.speak('Rooster', "Dutch Female", {volume: this.props.appData.volume})} to={ parseInt(this.props.weekScheduleEnabled, 10) !== 1 ? "/shifts/" + dayNum : '/shifts/week'} activeClassName="active" className={this.props.currentComponent === 'shifts' ? 'active' : ''}>
								<li className="button" id="present" data-url="/site/present">
									<img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/present.png"} alt={''}/>
									<span className="title">Rooster</span>
								</li>
							</NavLink>
							<NavLink onClick={()=> this.props.responsiveVoice.speak('Taken', "Dutch Female", {volume: this.props.appData.volume})} to={"/tasks/" + dayNum}  activeClassName="active" className={this.props.currentComponent === 'tasks' ? 'active' : ''}>
								<li className="button" id="tasks" data-url="/site/tasks">
									<img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/tasks.png"} alt={''}/>
									<span className="title">Taken</span>
								</li>
							</NavLink>
							<NavLink onClick={()=> this.props.responsiveVoice.speak('Activiteiten', "Dutch Female", {volume: this.props.appData.volume})} to="/activities" activeClassName="active" className={this.props.currentComponent === 'activities' ? 'active' : ''}>
								<li className="button" id="activities" data-url="/site/activities">
									<img src={"//files.smartdevelopment.nl/"+this.props.client+"/wonen/icons/activities.png"} alt={''}/>
									<span className="title">Activiteiten</span>
								</li>
							</NavLink>
						</ReactCSSTransitionGroup>
					</ul>
				</nav>
			</footer>
	    )
	}
}

function mapStateToProps(state) {
    return {
    	appData: state.appData,
		responsiveVoice: state.appData.responsiveVoice,
		client: state.appData.client,
    };
}


export default connect(mapStateToProps)(Footer);
