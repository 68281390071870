import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import moment from 'moment';

class TaskBlock extends React.Component{

	render() {

		let start_time = "";
		if (this.props.item.start_time != null) {
			start_time = moment(this.props.item.start_time, 'HH:mm').format('HH:mm');
		}

		return (
			<div className={"col-"+this.props.colClass+"-holder"}>
				<ReactCSSTransitionGroup transitionName="shifts" transitionAppear={true} transitionEnter={false} transitionLeave={false} transitionAppearTimeout={150000000} transitionEnterTimeout={0} transitionLeaveTimeout={0}>
					<div className="col-3" key={this.props.item.id}>
						{parseInt(this.props.showClientPicture, 10) === 1 ?
							 <div className="block-img dual">
							 	<img onClick={()=> this.props.responsiveVoice.speak(this.props.item.client, "Dutch Female", {volume: this.props.appData.volume})} src={this.props.item.clientImage} alt={''}/>
			                    <img className="taskIcon" onClick={()=> this.props.responsiveVoice.speak(this.props.item.title, "Dutch Female", {volume: this.props.appData.volume})} src={this.props.item.icon} alt={''}/>
			                </div>
						:
		                <div className="block-img">
		                    <img src={this.props.item.icon} alt={''}/>
		                </div>
		            	}
		                <span className="block-title" onClick={()=> this.props.responsiveVoice.speak(this.props.item.title, "Dutch Female", {volume: this.props.appData.volume})}>{this.props.item.title}</span>
		                <span className="block-content" onClick={()=> this.props.responsiveVoice.speak(this.props.item.client, "Dutch Female", {volume: this.props.appData.volume})}>{this.props.item.client}</span>
		            	<span className="block-subcontent" onClick={()=> this.props.responsiveVoice.speak(start_time, "Dutch Female", {volume: this.props.appData.volume})}>{start_time}</span>
		            </div>
		        </ReactCSSTransitionGroup>
		    </div>


		)
	}
}

function mapStateToProps(state) {
    return {
    	appData: state.appData,
        responsiveVoice: state.appData.responsiveVoice
    };
}
export default connect(mapStateToProps)(TaskBlock);
