import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Home from '../Home/Home';
import Menu from '../Menu/Menu';
import Tasks from '../Tasks/Tasks';
import Activities from '../Activities/Activities';
import Shifts from '../Shifts/Shifts';
import Present from '../Present/Present';
import Spotify from '../Platforms/Spotify';
import Netflix from '../Platforms/Netflix';
import Karaoke from '../Platforms/Karaoke';
import LoginForm from './LoginForm';
import Forecast from '../Forecast/Forecast';

import _config from '../../config';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { requestAppData, setAppTime, setTimeStamp, appDataFailure } from '../../actions';

class App extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.startTime = this.startTime.bind(this);
        this.enlargeSpotify = this.enlargeSpotify.bind(this);
        this.minifySpotify = this.minifySpotify.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.setUserLoggedIn = this.setUserLoggedIn.bind(this);
        this.setUserLoggedOut = this.setUserLoggedOut.bind(this);
        this.state = {
            spotifyMinified: true,
            userLoggedIn: false
        }
    }

    enlargeSpotify() {
        this.setState({spotifyMinified: !this.state.spotifyMinified});
    }

    minifySpotify() {
        this.setState({spotifyMinified: true});
    }

    fetchData() {

        let userIdUrl = '';

        if(localStorage.getItem('user_id')) {
            userIdUrl = '/loggedInUserId/'+localStorage.getItem('user_id');
        }
        fetch(this.props.apiURL + 'getdata/groupId/' + this.props.groupId + userIdUrl)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((application) => {
                this.updateLocalStorage(application);

            })
            .catch(() => {
                //this.props.appDataFailure(null);

            });

    }

    updateLocalStorage(appData) {

        localStorage.setItem('application', JSON.stringify(appData));

        this.props.requestAppData(appData);
    }

    startTime() {

        const today = new Date();
        const h = today.getHours();
        let m = today.getMinutes();
        let s = today.getSeconds();
        m = this.checkTime(m);
        s = this.checkTime(s);

        setTimeout(this.startTime, 60000);

        this.props.setAppTime({
            minutes: m,
            hours: h,
            seconds: s
        });
    }

    _inactivityTime() {
        var t;
        resetTimer();
        window.onload = resetTimer;
        document.onload = resetTimer;
        document.onclick = resetTimer;
        const app = this;
        function pushToHome() {
            app.props.history.push('/');
        }

        function resetTimer() {
            clearTimeout(t);
            t = setTimeout(pushToHome, 60000)
        }
    };





    componentDidMount() {
        this._inactivityTime();
        let appData = localStorage.getItem('application')
        if(appData) {

            if(appData) {
                if(appData !== undefined && appData !== 'undefined' && appData !== null) {

                    this.props.requestAppData(JSON.parse(appData));
                }else{
                    this.fetchData();
                }

            }else{
                this.fetchData();
            }

            if(localStorage.getItem('user_id')) {
                this.setState({
                    userLoggedIn: true
                })
            }

        } else {
            this.fetchData();
        }

        this.startTime();

    }


    checkTime(i) {
        if (i < 10) {
            i = "0" + i
        }
        //-- Function will be executed every second anyway, so we may as well use it to fetch the data if the last time has been longer than 60 seconds ago
        const now = Math.floor(Date.now() / 1000);
        const timestamp = this.props.appData.application.timestamp;
        if ((now - timestamp) >= _config.fetchInterval) {

            this.fetchData();

            let timestamp = this.props.appData.application;

            timestamp.timestamp += _config.fetchInterval;

            this.props.setTimeStamp(timestamp.timestamp);
        }

        return i;
    }

    setUserLoggedIn() {
        this.setState({userLoggedIn: true});
    }

    setUserLoggedOut() {
        this.setState({userLoggedIn: false});
    }

    getMarqueeStrings() {
        return this.props.appData.application.marqueeStrings;
    }

    render() {

        let loggedInValue = this.state.userLoggedIn === true ? 1 : 0;
        return (
            <div>
                <Header  enlargeSpotify={this.enlargeSpotify} isUserLoggedIn={loggedInValue}/>
                <div id="content">
                    <Spotify spotifyListUrl={this.props.appData.application.spotifyListUrl} class={this.state.spotifyMinified ? 'tiny' : 'large'}/>
                    <Switch>
                        <Route
                            exact
                            path='/'
                            render={ (props) =>
                                    <Home />
                                    }/>

                         <Route
                            path='/gebruiker'
                            render={ (props) =>
                                    <LoginForm setUserLoggedOut={this.setUserLoggedOut} setUserLoggedIn={this.setUserLoggedIn} fetchData={this.fetchData} {...props}/>
                                    }/>
                        <Route
                            exact
                            path='/spotify'
                            render={ (props) =>
                                    <Home />
                                    }/>

                        <Route
                            exact
                            path='/netflix'
                            render={ (props) =>
                                    <Netflix />
                                    }/>

                        {<Route
                            exact
                            path='/karaoke'
                            render={ (props) =>
                                    <Karaoke />
                                    }/>}

                        <Route
                            path='/menu/:day'
                            render={ (props) =>
                                    <Menu {...props}/>
                                    }/>

                        <Route
                            path='/shifts/:day'
                            render={ (props) =>
                                    <Shifts {...props}/>
                                    }/>

                        <Route
                            path='/tasks/:day'
                            render={ (props) =>
                                    <Tasks {...props}/>
                                    }/>
                        <Route
                            path='/activities'
                            render={ (props) =>
                                    <Activities  {...props}/>
                                    }/>

                        <Route
                            path='/forecast'
                            render={ (props) =>
                                    <Forecast  {...props}/>
                                    }/>

                        <Route
                            path='/present'
                            render={ (props) =>
                                    <Present  {...props}/>
                                    }/>

                    </Switch>
                </div>
                <Footer weekScheduleEnabled={this.props.appData.application.show_week_schedule} minifySpotify={this.minifySpotify}  currentComponent={this.props.appData.currentComponent} />
                </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        appData: state.appData
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestAppData: data => dispatch(requestAppData(data)),
        appDataFailure: data => dispatch(appDataFailure(data)),
        setAppTime: data => dispatch(setAppTime(data)),
        setTimeStamp: data => dispatch(setTimeStamp(data))

    };
};

const RootApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

export default RootApp
