import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
class EmptyBlock extends React.Component{

	render() {
		
		return (
			<div className="holder-col-4 emptyblock" onClick={()=> this.props.responsiveVoice.speak("Er zijn geen activiteiten gepland.", "Dutch Female", {volume: this.props.appData.volume})}>	
				<ReactCSSTransitionGroup
					transitionName="shifts"
					transitionAppear={true}
					transitionEnter={false}
					transitionLeave={false}
					transitionAppearTimeout={150000000}
					transitionEnterTimeout={0}
					transitionLeaveTimeout={0}>

						<div className="small-title">
							Geen activiteiten
						</div>
						<div className="full-size">
							<img src={''} alt={''}/>
						</div>
						<div className="wide-title">
							Geen activiteiten bekend
						</div>

				</ReactCSSTransitionGroup>
			</div>


		)
	}
}

function mapStateToProps(state) {
    return {
    	appData: state.appData,
        responsiveVoice: state.appData.responsiveVoice
    };
}
export default connect(mapStateToProps)(EmptyBlock);
