import React from 'react';
import DayList from '../Subcomponents/DayList';
import MenuBlock from '../Menu/MenuBlock';
import EmptyBlock from './EmptyBlock';

import { connect } from 'react-redux';
import {setIconAndName, setCurrentComponent} from '../../actions';


class Menu extends React.Component{
	renderBlocks(blocks) {
		let aItems = [];

		for (let prop in blocks) {
		    if (blocks.hasOwnProperty(prop)) {
		        let item = blocks[prop];
		        aItems.push(this.renderItem(item));
		    }
		}
		return aItems;
	}

	renderItem(item) {
		let headerClass = parseInt(this.props.appData.application.header_same_color, 10) === 1 ? "color-day-" + this.props.match.params.day : '';
		return <MenuBlock headerClass={headerClass} item={item} key={item.id} day={this.props.match.params.day}/>        
	}

	componentDidMount() {
		this.props.setCurrentComponent({currentComponent:'menu'});
		this.props.setIconAndName({routeName:'Eten', routeIcon:'//files.smartdevelopment.nl/'+this.props.client+'/wonen/icons/food.png'});
	}
	
	render() {
		let headerClass = parseInt(this.props.appData.application.header_same_color, 10) === 1 ? "color-day-" + this.props.match.params.day : '';
		return (
			
			<div className="menuHolder">
				<DayList basePath="/menu" currentDay={this.props.match.params.day}/>
				{this.props.appData.application.menu  ? this.props.appData.application.menu[this.props.match.params.day] ? this.renderBlocks(this.props.appData.application.menu[this.props.match.params.day]) : <EmptyBlock weekDay={this.props.match.params.day} headerClass={headerClass} type="menu" /> : <EmptyBlock weekDay={this.props.match.params.day} headerClass={headerClass} type="menu" />}
			</div>
		)
	}
}

function mapStateToProps(state) {
    return {
        appData: state.appData,
        client: state.appData.client,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIconAndName: data => dispatch(setIconAndName(data)),
        setCurrentComponent: data => dispatch(setCurrentComponent(data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);