import React from 'react';
import { connect } from 'react-redux';
import {setClientToActivity} from '../../actions';
import _config from '../../config';

class Client extends React.Component{

	constructor(context, props) {
		super(context, props);

		this.setClientToActivity = this.setClientToActivity.bind(this);
	}
	setClientToActivity() {

		this.props.responsiveVoice.speak((this.props.client.toActivity[this.props.activityId] ? 'Ik ga niet mee!' : 'Ik ga mee!'), "Dutch Female", {volume: this.props.appData.volume})
		this.props.setClientToActivity({activityId: this.props.activityId, clientId: this.props.client.id, dayId:this.props.day})

		var formData = new FormData();


        formData.append('clientId', this.props.client.id);
        formData.append('activityId', this.props.activityId);


        fetch(this.props.apiUrl + 'setclienttoactivity', {
            method: 'POST',
            headers: ({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin,Headers'


            }),
            body: formData
        });
	}

	render () {
		return (
		 	<li id="client-holder-2" className={"client-to-menu "  +  this.props.client.toActivity[this.props.activityId]} >
				<div onClick={()=> this.props.responsiveVoice.speak(this.props.client.name, "Dutch Female", {volume: this.props.appData.volume})} style={{float : 'left', color : 'black'}}>{this.props.client.name}</div>
				<span className="inner-button" onClick={this.setClientToActivity}>
					{this.props.client.toActivity[this.props.activityId] ? 'Ik ga mee!' : 'Ik ga niet mee!' }
				</span>
			</li>

		)
	}
}

function mapStateToProps(state) {
    return {
        appData: state.appData,
        apiUrl: state.apiUrl.ApiUrl,
		responsiveVoice: state.appData.responsiveVoice
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setClientToActivity: data => dispatch(setClientToActivity(data)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Client);
