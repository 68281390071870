import React from 'react';
import ActivityBlock from '../Activities/ActivityBlock';
import EmptyBlock from './EmptyBlock';
import { connect } from 'react-redux';
import {setIconAndName, setCurrentComponent} from '../../actions';
//-- Activities component
class Activities extends React.Component{
	constructor(props, context) {
	    super(props, context);
	    this.renderActivities = this.renderActivities.bind(this);

	    const d = new Date();
		const dayNumber = d.getDay();
		this.dayNum = 1;
		if(dayNumber === 0) {
			this.dayNum = 7;
		}
		else{
			this.dayNum = dayNumber;
		}

	}

	componentDidMount () {
		this.props.setCurrentComponent({currentComponent:'activities'});
		this.props.setIconAndName({routeName:'Activiteiten', routeIcon:'//files.smartdevelopment.nl/'+this.props.client+'/wonen/icons/activities.png'});
	}
	renderActivities(key) {
		return <ActivityBlock day={this.dayNum} key={key} {...this.props.appData.application.activities[this.dayNum][key]}/>;
	}
	render() {
		return (
			<div id="activities">
				<div className="row">
					{this.props.appData.application.activities[this.dayNum]  ? Object.keys(this.props.appData.application.activities[this.dayNum]).map(this.renderActivities) : <EmptyBlock />}
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
    return {
        appData: state.appData,
        client: state.appData.client,

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIconAndName: data => dispatch(setIconAndName(data)),
        setCurrentComponent: data => dispatch(setCurrentComponent(data)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Activities);
