import { REQUEST_APPDATA, SET_APPLICATION_TIME, SET_ICON_AND_ROUTE, SET_TIMESTAMP, SET_CURRENT_COMPONENT, SET_CLIENT_TO_MENU, SET_CLIENT_PRESENCE, SET_CLIENT_TO_ACTIVITY, DATA_FAILURE } from '../actions';

const initialState = {
    client: 'philadelphia',
    routeIcon: '',
    routeName: '',
    currentComponent: '',
    seconds: '',
    minutes: '',
    hours: '',
    application: {
        timestamp: '',
        days: {},
        home: {
            colClass: '6',
            day: '',
            date: '',
            weather: {
                temperature: '',
                text: ''
            },
        },
        activities: {},
        shifts: {},
        menus: {}
    },
    'responsiveVoice': null,
    volume: 1

}

export default function AppDataReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_CLIENT':
            return {
                ...state,
                client: action.payload
            }
        case REQUEST_APPDATA:
            return {
                ...state,
                application: action.payload
            };

        case SET_APPLICATION_TIME:
            return {
                ...state,
                minutes: action.payload.minutes,
                seconds: action.payload.seconds,
                hours: action.payload.hours
            };

        case SET_ICON_AND_ROUTE:
            return {
                ...state,
                routeIcon: action.payload.routeIcon,
                routeName: action.payload.routeName
            }

        case SET_TIMESTAMP:
            return {
                ...state,
                application: {
                    ...state.application,
                    timestamp: action.payload
                }
            }
        case SET_CURRENT_COMPONENT:
            return {
                ...state,
                currentComponent: action.payload.currentComponent
            }
        case SET_CLIENT_TO_MENU:
            return {
                ...state,
                application: {
                    ...state.application,
                    clients: {
                        ...state.application.clients,
                        [action.payload.dayId]: {
                                ...state.application.clients[action.payload.dayId],
                              [action.payload.clientId]: {
                                ...state.application.clients[action.payload.dayId][action.payload.clientId],
                                toMenu: {
                                    ...state.application.clients[action.payload.dayId][action.payload.clientId].toMenu,
                                    [action.payload.menuId]: !state.application.clients[action.payload.dayId][action.payload.clientId].toMenu[action.payload.menuId]
                                }
                            }
                        }
                      

                    }

                }
            }
        case SET_CLIENT_PRESENCE:
            return {
                ...state,
                application: {
                    ...state.application,
                    clients: {
                        ...state.application.clients,
                        [action.payload.dayId]: {
                            ...state.application.clients[action.payload.dayId],
                            [action.payload.clientId]: {
                                ...state.application.clients[action.payload.dayId][action.payload.clientId],
                                is_present: action.payload.isPresent.toString()
                            }
                        }
                    }
                }
            }
        case SET_CLIENT_TO_ACTIVITY:
            return {
                ...state,
                application: {
                    ...state.application,
                    clients: {
                        ...state.application.clients,
                        [action.payload.dayId] : {
                            ...state.application.clients[action.payload.dayId],
                            [action.payload.clientId]: {
                                ...state.application.clients[action.payload.dayId][action.payload.clientId],
                                toActivity: {
                                    ...state.application.clients[action.payload.dayId][action.payload.clientId].toActivity,
                                    [action.payload.activityId]: !state.application.clients[action.payload.dayId][action.payload.clientId].toActivity[action.payload.activityId]
                                }
                            }
                        }

                    }

                }
            }
        case DATA_FAILURE:
             return {
                ...state,
                application: {
                    ...state.application,
                    marqueeStrings: ['Let op: geen verbinding']

                }
            }
        case 'RESPONSIVE_VOICE':
      
        return {
                ...state,
                responsiveVoice: action.payload
            }

        default:
            return state;
    }
}
